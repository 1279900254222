<template>
  <div class="w100s">
    <div style="display: flex;justify-content: space-between;align-items: center;background-color: #1352ff;padding: 1rem;">
      <div>
        <img style="width: 7rem;" src="@/assets/image/logo.png" />
      </div>
      <div class="ljqb" @click="initWeb3Account">
        <div v-if="defaultAddress.length > 10">{{ defaultAddress| ellipsis }}</div>
        <div v-if="defaultAddress.length < 1">{{$t('lang.61')}}</div>
      </div>
      <div class="ljqb">
        <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect" theme="dark" placement="bottom-end">
          <template #reference>
            <div style="display: flex;
                  justify-content: space-between;
                  align-items: center; width: 3rem;">
              <div><country-flag :country='$i18n.locale' size='small'/></div>
              <div>
                <svg style="width: 1rem;height: 1rem" t="1658806784286" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3253" width="200" height="200"><path d="M503.466667 610.133333l29.866666 29.866667 29.866667-29.866667 115.2-115.2-29.866667-29.866666-115.2 115.2-115.2-115.2-29.866666 29.866666 115.2 115.2zM533.333333 170.666667C332.8 170.666667 170.666667 332.8 170.666667 533.333333S332.8 896 533.333333 896s362.666667-162.133333 362.666667-362.666667S733.866667 170.666667 533.333333 170.666667z m0 42.666666c174.933333 0 320 145.066667 320 320S708.266667 853.333333 533.333333 853.333333 213.333333 708.266667 213.333333 533.333333 358.4 213.333333 533.333333 213.333333z" fill="#ffffff" p-id="3254"></path></svg>
              </div>
            </div>
          </template>
        </van-popover>
      </div>
    </div>

    <div style="background-color: #1352ff;padding: 0 2rem;padding-bottom: 1rem;border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;">
      <div style="text-align: center;color: #ffffff;padding: 3rem 0rem;">
        <div>{{$t('lang.62')}}{{ getSettings.pool_name }}</div>
        <div style="font-size: 1.5rem;">{{ addressxx.pt_money || 0}}{{ getSettings.pool_name }}</div>
      </div>
    </div>

    <div style="padding: 0 2rem;padding-bottom: 5rem;">
      <div style="background-color: #1352ff;padding: 1rem;
    border-radius: 0.5rem;margin-top: 2rem;">
        <div style="display: flex;justify-content: space-between;align-items: center;color: #fff;">
          <div>{{$t('lang.63')}}</div>
          <div>{{ defaultAddress| ellipsis }}</div>
        </div>
      </div>


      <div style="background-color: #1352ff;padding: 1rem;
        border-radius: 0.5rem;margin-top: 1rem;">
        <div class="duihuan">
          <div>{{$t('lang.64')}}</div>
          <div>1{{ getSettings.pool_name }}={{ getSettings.price }}USDT</div>
        </div>
        <div class="duihuan">
          <div>{{$t('lang.65')}}</div>
          <div>={{ ptbxx.dh }}USDT</div>
        </div>
        <div style="color: #fff;font-size: 1rem">{{$t('lang.66')}}</div>
        <div style="display: flex;
            justify-content: space-between;
            align-items: center;">
          <div>
            <Input-number @on-change="ptvalue" style="width: 150px" :max="99999" :min="1" v-model="ptbxx.value"></Input-number>
          </div>
          <div style="display: flex;
          font-size: 1rem;
          color: #fff;align-items: center;">
            <div>{{ getSettings.pool_name }}</div>
            <div style="background-color: #2f33b2;
            padding: 0.5rem;
            border-radius: 0.5rem;
            font-size: .9rem;margin-left: 0.2rem;" @click="maxeos">MAX</div>
          </div>
        </div>
        <div style="text-align: center;margin-top: 0.5rem;">
          <div class="jl" @click="dhusdt">{{$t('lang.67')}}</div>
          <div class="jl" @click="tolog">{{$t('lang.68')}}</div>
        </div>
      </div>


      <div style="background-color: #1352ff;padding: 1rem;
        border-radius: 0.5rem;margin-top: 1rem;">
        <div class="duihuan">
          <div>{{$t('lang.69')}}</div>
          <div>1{{ getSettings.pool_name }}={{ getSettings.price }}USDT</div>
        </div>
        <div class="duihuan">
          <div>{{$t('lang.70')}}</div>
          <div>={{ usdtxx.dh }}{{ getSettings.pool_name }}</div>
        </div>
        <div style="color: #fff;font-size: 1rem">{{$t('lang.71')}}</div>
        <div style="display: flex;
            justify-content: space-between;
            align-items: center;">
          <div>
            <Input-number @on-change="usvalue" style="width: 150px" :max="99999" :min="1" v-model="usdtxx.value"></Input-number>
          </div>
          <div style="display: flex;
          font-size: 1rem;
          color: #fff;align-items: center;">
            <div>USDT</div>
            <div style="background-color: #2f33b2;
            padding: 0.5rem;
            border-radius: 0.5rem;
            font-size: .9rem;margin-left: 0.2rem;" @click="maxusdt">MAX</div>
          </div>
        </div>
        <div style="text-align: center;margin-top: 0.5rem;">
          <div class="jl" @click="dheos">{{$t('lang.72')}}{{ getSettings.pool_name }}</div>
          <div class="jl" @click="tolog">{{$t('lang.73')}}</div>
        </div>
      </div>


      <div style="background-color: #1352ff;padding: 1rem;
    border-radius: 0.5rem;margin-top: 2rem;">
        <div style="display: flex;justify-content: space-between;align-items: center;color: #fff;">
          <div>{{$t('lang.74')}}</div>
        </div>
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item,index) in news" :title="item.title" :name="item.title" :key="index">
            <div style="white-space: pre-wrap;" >{{item.content}}</div>
          </van-collapse-item>
        </van-collapse>
      </div>

    </div>

  </div>

</template>

<script>
import axios from "@/utils/axios";
import Web3 from "web3";
import bl1 from "@/assets/image/lb1.png";
import bl2 from "@/assets/image/lb2.png";
import bl3 from "@/assets/image/lb3.png";
import { Toast } from 'vant';
import { formatDate } from '@/utils';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {USDTbalanceOf ,getAccounts} from "@/utils/Mapping";
// Web3modal instance
let web3Modal
// Chosen wallet provider given by the dialog window
let provider;
export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      ptbxx:{
        value:0,
        dh:0,
      },
      usdtxx:{
        value:0,
        dh:0,
      },
      defaultAddress:'',
      usdtmoney:'0',
      current: 0,
      value1:1,
      images: [
        bl1,
        bl2,
        bl3,
      ],
      news:[

      ],
      activeNames: ['0'],
      activeName: ['0'],
      activeNamee: ['0'],
      showPopover: false,
      actions: [
        { text: 'Engilsh',key:'gb' },
        { text: 'Chinese',key:'cn'  },
        { text: 'Japan',key:'jp'  },
        { text: 'Spain',key:'es'  },
        { text: 'Vietnam',key:'vn'  },
      ],
      addressxx:{
        pt_money:0,
      },
      getSettings:{},
    };
  },
  mounted() {
  },
  watch: {

  },
  components: {
  },

  //初始化
  async created(){
    //网络添加切换
    // await this.getChainId()
    await this.chushi()
    await this.initWeb3Account()
  },

  methods: {
    onChange(index) {
      this.current = index;
    },
    async chushi(){
      // 初始化
      console.log("Initializing example");
      console.log("WalletConnectProvider is", WalletConnectProvider);
      const providerOptions = {
        /* See Provider Options Section */
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "9321e08afdc04e2c81cabc499dc5d569" // required
          }
        }
      };

      web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        disableInjectedProvider: false,
        providerOptions // required
      });



    },
    async tolog(){
      this.$router.push({ path: "/fundslog" });
    },
    async ptvalue(){
      console.log('ptvalue')
      this.ptbxx.dh = this.ptbxx.value*this.getSettings.price
    },
    async usvalue(){
      console.log('usvalue')
      this.usdtxx.dh = (this.usdtxx.value/this.getSettings.price).toFixed(2)

    },
    async maxeos(){
      this.ptbxx.value = this.addressxx.pt_money
      this.ptbxx.dh = this.addressxx.sy_money/this.getSettings.price
    },
    async maxusdt(){
      this.usdtxx.value = this.addressxx.sy_money
      this.usdtxx.dh = (this.addressxx.pt_money*this.getSettings.price).toFixed(2)
    },
    async dhusdt(){
      let that = this
      axios.post('/index/dh_coin',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,num:this.ptbxx.value,coin:'1'})
          .then(function (response) {
            console.log('dh_coin',response.data);
            if(response.data.code == 1){
              Toast.success(that.$t('lang.81'));
            }else {
              Toast.fail("余额不足,兑换失败");
            }
          })
      this.reload();
    },
    async dheos(){
      let that = this
      axios.post('/index/dh_coin',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,num:this.usdtxx.value,coin:'0'})
          .then(function (response) {
            console.log('dh_coin',response.data);
            if(response.data.code == 1){
              Toast.success(that.$t('lang.82'));
            }else {
              Toast.fail("余额不足,兑换失败");
            }
          })
      this.reload();
    },

    async initWeb3Account() {
      var lang =localStorage.getItem('locale')
      var agent = localStorage.getItem('agent')
      console.log(this.$t('lang.83'));
      // 检查新版MetaMask
      this.lianname = this.$store.state.daou.chainId
      console.log(this.$t('lang.51'));
      // 检查新版MetaMask
      if(this.lianname != "TRC"){
        provider = await web3Modal.connect();
        this.web3 = new Web3(provider);
      }
      getAccounts().then(accs => {
        this.defaultAddress = accs
        console.log(accs)
        USDTbalanceOf(this.defaultAddress).then(res =>{
          console.log("USDTbalanceOf",res)
          this.usdtmoney = res/(10**this.$store.state.daou.decimal);
          //地址信息
          let that = this
          axios.post('/index/getaddressxx',{address: this.defaultAddress,chainId:this.$store.state.daou.chainId,})
              .then(function (response) {
                console.log('getaddressxx',response.data);
                that.addressxx = response.data.data
              })


          //初始设置
          axios.post('/index/getSettings', {address: this.defaultAddress,chainId:this.$store.state.daou.chainId,agent:agent})
              .then(function (response) {
                if(response.data.code == 1){
                  that.getSettings = response.data.data;
                  localStorage.setItem('settings',response.data.data)
                }
              })
          //公告
          axios.post('/index/getnews', {lang:lang,agent:agent,type:1})
              .then(function (response) {
                console.log('getnews',response.data);
                that.news = response.data.data
              })

        })

      })


    },

    onSelect(action) {
      console.log(action)
      this.$i18n.locale = action.key
      localStorage.setItem('locale',action.key)
      this.reload();
    },
    async canyu(){
      Toast(this.$t('lang.85'));
      console.log('canyu');
    },
    async getChainId(){
      let id = window.ethereum.chainId;
      console.log(id)
      if(id != '0x38'){
        window.ethereum.request({
          method: 'wallet_addEthereumChain', // Metamask的api名称
          params: [{
            chainId: "0x38", // 网络id，16进制的字符串
            chainName: "BNB Smart Chain Mainnet", // 添加到钱包后显示的网络名称
            rpcUrls: [
              'https://bsc-dataseed1.ninicoin.io/', // rpc地址
            ],
            iconUrls: [
              'https://testnet.hecoinfo.com/favicon.png' // 网络的图标，暂时没看到在哪里会显示
            ],
            blockExplorerUrls: [
              'https://bscscan.com/' // 网络对应的区块浏览器
            ],
            nativeCurrency: {  // 网络主币的信息
              name: 'bnb',
              symbol: 'bnb',
              decimals: 18
            }
          }]
        });
        // window.location.reload();
        console.log('no')
      }else {
        console.log('yes')
      }
      //！链id不是马上拿到的，如果通过链id来判断是不是主网的方式，请注意异步
    },

  },
  filters: {
    ellipsis(value) {
      let len = value.length;
      if (!value) return "";
      if (value.length > 8) {
        return value.substring(0, 4) + "..." + value.substring(len - 4, len);
      }
      return value;
    },
    formatDate(time) {
      time = time * 1000
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm');
    }
  },
}
</script>
<style>
.jl{
  color: #fff;
  background-color: #2f33b2;
  margin-bottom: 0.5rem;
  font-size: .9rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.duihuan{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid;
  margin-bottom: 1.5rem;
}
.kkk{
  background: #2f33b2;
  padding: 0.3rem;
  width: 8rem;
  text-align: center;
  border-radius: 0.5rem;
}
.cyan{
  background-color: #2f33b2;
  /* padding: 0.5rem; */
  width: 6rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.ljqb{
  border-radius: 0.5rem;
  background-color: #2f33b2;
  padding: 0.5rem;
  color: #fff;
  font-size: 0.5rem;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #2f33b2;
}
.van-cell--clickable:active {
  background-color: #2f33b2;
}
.van-collapse-item {
  position: relative;
  background: #2f33b2;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.van-hairline--top-bottom {
  /*position: relative;*/
  margin-top: 1rem;
}
</style>
<style scoped>
.van-cell {
  color: #fff;
  background-color: #1352ff;
}
.van-collapse-item__content {
  color: #fff;
  background-color: #1352ff;
}
.van-cell__right-icon {
  margin-left: 4px;
  color: #ffffff;
}
</style>
